@moonfit-prefix: mf;

@mf-primary-color: #4ccbc9;
@mf-primary-color-2: #e4007b;
@mf-secondary-color: #a16bd8;

@mf-bg-1: #020722;
@mf-bg-2: #351c4f;
@mf-bg-3: #452863;
@mf-bg-4: #2a1143;
@mf-bg-5: #efaa5d;

@mf-border-radius-base: 10px;

@mf-text-color: #A8ADC3;

/* Media queries breakpoints */
// @mf-screen-xs and @mf-screen-xs-min is not used in Grid
// smallest break point is @mf-screen-md
@mf-screen-xs: 480px;
@mf-screen-xs-min: @mf-screen-xs;
// 👆 Extra small screen / phone

// 👇 Small screen / tablet
@mf-screen-sm: 576px;
@mf-screen-sm-min: @mf-screen-sm;

// Medium screen / desktop
@mf-screen-md: 768px;
@mf-screen-md-min: @mf-screen-md;

@mf-screen-md-tablet: 851px;
@mf-screen-md-min-tablet: @mf-screen-md-tablet;

// Large screen / wide desktop
@mf-screen-lg: 992px;
@mf-screen-lg-min: @mf-screen-lg;

// Extra large screen / full hd
@mf-screen-xl: 1200px;
@mf-screen-xl-min: @mf-screen-xl;

// Extra extra large screen / large desktop
@mf-screen-xxl: 1600px;
@mf-screen-xxl-min: @mf-screen-xxl;

// provide a maximum
@mf-screen-xs-max: (@mf-screen-sm-min - 1px);
@mf-screen-sm-max: (@mf-screen-md-min - 1px);
@mf-screen-md-max-tablet: (@mf-screen-md-min-tablet - 1px);
@mf-screen-md-max: (@mf-screen-lg-min - 1px);
@mf-screen-lg-max: (@mf-screen-xl-min - 1px);
@mf-screen-xl-max: (@mf-screen-xxl-min - 1px);

/* Mixin */
.e(@element; @content) {
    &__@{element} {
        @content();
    }
}

.m(@modifier; @content) {
    &.-@{modifier} {
        @content();
    }
}

.mf-border-gradient {
    background-color: @mf-bg-2;
    border: 2px solid transparent;
    background-clip: padding-box;
    border-radius: @mf-border-radius-base;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        inset: 0;
        z-index: -1;
        margin: -2px;
        border-radius: inherit;
        background-image: linear-gradient(to right bottom, #4cbfcb, #E4007B);
    }
}
